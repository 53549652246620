<template>
  <div :class="positionClass">
    <ul class="p-0.5 inline-flex bg-gray-200 text-sm font-medium text-gray-500 rounded">
      <li v-for="tab in tabs" :key="tab.value">
        <button
          type="button"
          class="py-1 px-3 rounded-sm focus:outline-none"
          :class="[
            modelValue === tab.value
              ? 'shadow-sm  bg-white'
              : 'hover:bg-gray-300 hover:text-gray-600 focus:text-gray-600',
            {
              'text-pink-300 hover:text-pink-300 focus:text-pink-300':
                modelValue === tab.value && !themeEnabled,
              'text-black hover:text-black focus:text-black':
                modelValue === tab.value && themeEnabled,
            },
          ]"
          :data-testid="`tab-${tab.value}`"
          @click="selectTab(tab.value)"
        >
          {{ tab.label }}
        </button>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { usePageProps } from "@/composables/pageProps"
import { computed } from "vue"
const props = defineProps({
  tabs: Array, // [{label: 'Tab 1', value: 1}, ... ]
  modelValue: [Number, String], // value of tab
  position: {
    type: String,
    default: "left", // left, right, center
  },
})
const emit = defineEmits(["update:modelValue"])

const { themeEnabled } = usePageProps()

const selectTab = value => {
  emit("update:modelValue", value)
}
const positionClass = computed(() => {
  switch (props.position) {
    case "center":
      return "text-center"
    case "right":
      return "text-right"
  }
  return "text-left"
})
</script>

<style lang="scss" scoped></style>
